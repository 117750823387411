import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ProtectedLayoutComponent } from '../../shared/widgets/protected-layout/protected-layout.component';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeneralButtonDirective } from '../../shared/directives/buttons/general-button.directive';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { LookupService } from '../../core/services/lookup.service';
import { ObjectLiteral } from '../../core/models/ObjectLiteral';
import { formatBytes } from '../../core/utils/formatByte';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

interface SelectedDocProps {
  file: File;
  docId: number;
  docName: string;
  size: string;
}

interface DocListProps {
  [key: string | number]: SelectedDocProps;
}

@Component({
  selector: 'app-agent-file-upload',
  standalone: true,
  imports: [
    CommonModule,
    ProtectedLayoutComponent,
    SvgIconComponent,
    ReactiveFormsModule,
    NgSelectModule,
    GeneralButtonDirective,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './agent-file-upload.component.html',
  styleUrl: './agent-file-upload.component.css',
})
export class AgentFileUploadComponent {
  steps = [
    {
      index: 1,
      data: {
        heading: 'Log in to your account',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
    {
      index: 2,
      data: {
        heading: 'Upload Document',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
    {
      index: 3,
      data: {
        heading: 'Make payment',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
  ];

  lookupService = inject(LookupService);

  //get user types from endpoint
  queryResponse = injectQuery(() => ({
    queryKey: ['agent-doc-types'],
    queryFn: () => this.lookupService.agentDocumentLookup(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  }));

  currentStep = signal(2);

  authForm = new FormGroup({
    documentType: new FormControl(null, [Validators.required]),
  });
  selectedDocument = signal<ObjectLiteral | null>(null);
  selectDocumentWithFiles = signal<DocListProps>({});

  onSubmit() {}

  changeHandler(evt) {
    this.selectedDocument.set(evt);
  }
  changeFileHandler(evt, id: number) {
    const file = (evt.target as HTMLInputElement).files[0];

    this.selectDocumentWithFiles.update((curr) => ({
      ...curr,
      ...(id
        ? {
            [id]: {
              file,
              docId: id,
              docName: file.name,
              size: formatBytes(file.size),
            },
          }
        : {}),
    }));
  }

  get selectedDocs() {
    const arr = [];
    for (const [key, val] of Object.entries(this.selectDocumentWithFiles())) {
      arr.push(val);
    }
    return arr;
  }
  clickHandler(evt: MouseEvent) {
    return ((evt.target as HTMLInputElement).value = null);
  }
  deleteHandler(arg: string) {
    const selectedDoc = this.selectDocumentWithFiles()[arg];
    if (selectedDoc) {
      // How to delete a dynamic property from an object https://stackoverflow.com/a/52301527
      const { [arg]: idToDelete, ...others } = this.selectDocumentWithFiles();
      this.selectDocumentWithFiles.update((curr) => others);
    }
  }
}

//   <ngx-skeleton-loader
//             [theme]="{
//               width: '200px',
//               'border-radius': '0',
//               height: '15px',
//               'margin-bottom': '10px'
//             }"
//           />
