<ng-template #loadingTemplate let-templateData="data">
  <div
    [ngClass]="{'flex gap-3 text-white w-full':true,
                    '': true,
                    }"
  >
    <div class="flex-1">
      <p class="text-sm font-medium">
        <span>
          {{ templateData.content }}
        </span>
      </p>
    </div>
    <button aria-label="close" class="" (click)="closeNotification()">
      <svg-icon
        src="assets/svgs/cancel-icon-white.svg"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
      ></svg-icon>
    </button>
  </div>
</ng-template>

<ng-template #errorTemplate let-templateData="data">
  <div
    [ngClass]="{'flex gap-3 text-white w-full':true,
                    '': true,
                    }"
  >
    <div class="flex-1">
      <p class="text-sm font-medium">
        <span>
          {{ templateData.content }}
        </span>
      </p>
    </div>
    <button aria-label="close" class="" (click)="closeNotification()">
      <svg-icon
        src="assets/svgs/cancel-icon-white.svg"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
      ></svg-icon>
    </button>
  </div>
</ng-template>

<ng-template #loadingTemplate let-templateData="data">
  <div
    [ngClass]="{'flex gap-3 text-white w-full':true,
                    '': true,
                    }"
  >
    <div class="flex-1">
      <p class="text-sm font-medium">
        <span>
          {{ templateData.content }}
        </span>
      </p>
    </div>
    <button aria-label="close" class="" (click)="closeNotification()">
      <svg-icon
        src="assets/svgs/cancel-icon-white.svg"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
      ></svg-icon>
    </button>
  </div>
</ng-template>
