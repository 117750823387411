import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastCustomComponent } from './shared/widgets/toast/toast.component';
import { SpinnerComponent } from './shared/widgets/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [
    RouterModule,
    ToastCustomComponent,
    SpinnerComponent,
    NgxSpinnerModule,
  ],
})
export class AppComponent {
  title = 'PayKaduna';
}
