<app-header [hideRoutes]="false"></app-header>

<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">
    <div class="max-w-[600px] mx-auto pt-[40px]">
        <h3 class="text-[#000] text-2xl font-semibold pb-9">Reset password</h3>

        <div>
            <form class="w-full max-w-[611px] flex flex-col gap-[19px]" (ngSubmit)="onSubmit()" [formGroup]="authForm">

                <div class="flex w-full gap-4">
                    <div class="text-[#5D5E5E] flex flex-col relative w-full">


                        <app-password-input label="Password" placeholder="Password" controlName="password"
                            [formGroup]="authForm"></app-password-input>
                        <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                            *ngIf="!!authForm.get('password').errors && (authForm.get('password').touched||authForm.get('password').dirty)">
                            <span *ngIf="authForm.get('password').errors?.required">New password is required</span>

                        </div>
                    </div>
                </div>
                <div class="flex w-full gap-4">

                    <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                        <app-password-input label="Confirm password" placeholder="Confirm password"
                            controlName="confirmPassword" [formGroup]="authForm"></app-password-input>
                        <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                            *ngIf="!!authForm.get('confirmPassword').errors && (authForm.get('confirmPassword').touched||authForm.get('confirmPassword').dirty)">
                            <span *ngIf="authForm.get('confirmPassword').errors?.required">Confirm password is
                                required</span>

                        </div>
                    </div>

                </div>
                <div class="flex flex-col gap-2">
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.length,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.length}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Password must contain at least 8 characters</span>
                    </p>
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.upper,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.upper}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Password must contain at least one uppercase Letter</span>
                    </p>
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.lower,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.lower}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Password must contain at least one lowercase Letter</span>
                    </p>
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.numeric,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.numeric}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Password must contain at least one numeric digit</span>
                    </p>
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.symbol,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.symbol}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Password must contain at least one symbol</span>
                    </p>
                    <p
                        [ngClass]="{'flex items-center text-[#5D5E5E] text-sm gap-3': true,'text-red-500 [&_svg_path]:fill-red-500':!authForm.pristine&&authForm.errors?.matches,'text-tw-primary [&_svg_path]:fill-tw-primary':!authForm.pristine&&!authForm.errors?.matches}">
                        <svg-icon src="assets/svgs/check-circle.svg"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                        <span>Both password must match</span>
                    </p>
                </div>
                <div class="w-full mt-9">
                    <button [ngClass]="{'disabled:opacity-50':true }" appGeneralButton type="submit"
                        [disabled]="authForm.invalid||mutation.isPending()">{{mutation.isPending()?"Resetting password.\
                        Please wait...":'Reset \
                        password'}}</button>
                </div>
            </form>
        </div>
    </div>

</div>
