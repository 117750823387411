<div
  [ngClass]="{
    'px-12 2xl:px-[98px] mx-auto w-full min-h-[100vh] flex flex-col bg-img': true
  }"
>
  <div class="container flex flex-wrap items-center py-5 pt-12 mx-auto">
    <div class="flex items-center gap-2">
      <a
        class="flex items-center mb-3 font-medium text-gray-900 title-font md:mb-0"
        [routerLink]="'/'"
      >
        <img
          [src]="'assets/images/logo_with_name.png'"
          class="w-[181px] rounded-full overflow-hidden object-fill"
        />
      </a>
    </div>
  </div>

  <div
    [ngClass]="{
      'px-12 py-12 mx-auto w-full flex items-center justify-center': true
    }"
  >
    <nz-card
      [nzBordered]="false"
      [ngClass]="{
        'shadow-[0px_0px_20px_0px_rgba(6,6,6,0.1)] w-[550px] rounded-xl px-5 py-7 flex flex-col items-center overflow-hidden': true
      }"
      [nzBodyStyle]="{ width: '100%' }"
    >
      <span class="absolute top-[-5px] left-0">
        <svg-icon
          src="assets/svgs/3-circles.svg"
          [svgStyle]="{ 'width.px': 150, }"
        >
        </svg-icon>
      </span>

      <p class="text-4xl font-semibold text-center uppercase">Stay tuned</p>

      <div>
        <p [ngClass]="{ 'text-center text-xl font-bold pt-4': true }">
          We are currently upgrading this service to better serve the people of
          Kaduna State.
        </p>
        <p [ngClass]="{ 'text-center font-semibold text-lg pt-3': true }">
          We are committed to restoring the service within the nearest future.
        </p>
        <p [ngClass]="{ 'text-center font-semibold text-lg pt-3': true }">
          Thank you for understanding as we strive to serve you better.
        </p>
      </div>

      <!-- <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" class="mt-16">
        <nz-form-item>
          <nz-form-control nzErrorTip="Email is required">
            <nz-input-group
              nzSize="large"
              [nzAddOnAfter]="suffixButton"
              class="bg-[#F4F4F4] rounded-xl"
            >
              <input
                formControlName="email"
                type="text"
                nz-input
                placeholder="Email address"
                class="!bg-[#F4F4F4] border-0 h-[40px] px-4 text-sm"
              />
            </nz-input-group>

            <ng-template #suffixButton>
              <button
                type="submit"
                nz-button
                nzType="primary"
                nzSize="large"
                class="bg-tw-primary hover:bg-tw-primary focus:bg-tw-primary border-0 !w-full text-sm"
              >
                Notify Me
              </button>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form> -->
    </nz-card>
  </div>
</div>
