import { createReducer, on } from '@ngrx/store';
import { Action } from '../../models';
import * as lookupActions from '../actions/lookup.actions';
import { immerOn } from 'ngrx-immer/store';

export type LookupProps = {
  id: number;
  displayField: string;
  slug?: string;
};

export interface LookupState {
  gender: { data: LookupProps[]; isLoading: boolean };
  states: { data: LookupProps[]; isLoading: boolean };
  lgas: { data: LookupProps[]; isLoading: boolean };
  industries: { data: LookupProps[]; isLoading: boolean };
  expertSystems: { data: LookupProps[]; isLoading: boolean };
  revenueHeads: { data: LookupProps[]; isLoading: boolean };
  paymentChannels: { data: LookupProps[]; isLoading: boolean };
  paymentGateways: { data: LookupProps[]; isLoading: boolean };
  mda: { data: LookupProps[]; isLoading: boolean };
  taxPayerCategories: { data: LookupProps[]; isLoading: boolean };
  taxOffices: { data: LookupProps[]; isLoading: boolean };
}

export const initialLookupState: LookupState = {
  gender: { data: [], isLoading: true },
  states: { data: [], isLoading: true },
  lgas: { data: [], isLoading: true },
  industries: { data: [], isLoading: true },
  expertSystems: { data: [], isLoading: false },
  revenueHeads: { data: [], isLoading: false },
  paymentChannels: { data: [], isLoading: false },
  paymentGateways: { data: [], isLoading: false },
  mda: { data: [], isLoading: false },
  taxPayerCategories: { data: [], isLoading: false },
  taxOffices: { data: [], isLoading: false },
};

const reducer = createReducer(
  initialLookupState,
  on(lookupActions.taxOfficesLookupAction, (state, options) => ({
    ...state,
    taxOffices: {
      data: options.data,
      isLoading: options.isLoading,
    },
  })),
  on(lookupActions.genderlookupAction, (state, options) => ({
    ...state,
    gender: {
      data: options.data,
      isLoading: options.isLoading,
    },
  })),
  on(lookupActions.stateLookupAction, (state, options) => ({
    ...state,
    states: {
      data: options.data,
      isLoading: options.isLoading,
    },
  })),
  on(lookupActions.lgasLookupAction, (state, options) => ({
    ...state,
    lgas: {
      data: options.data,
      isLoading: options.isLoading,
    },
  })),
  on(lookupActions.industriesLookupAction, (state, options) => ({
    ...state,
    industries: {
      data: options.data,
      isLoading: options.isLoading,
    },
  })),
  on(lookupActions.setStateAction, (state, payload) => ({
    ...state,
    ...payload,
  }))
);

export function lookupReducer(state: LookupState, action: Action) {
  return reducer(state, action);
}
