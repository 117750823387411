import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private appService: AppService) {}

  getAllRoles(params: {
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) {
    return this.appService.get(`${APIConstant.role.getAllRoles}`, params);
  }

  getRole(params: { roleName: string }) {
    return this.appService.get(`${APIConstant.role.getRole}`, params);
  }

  getUserRoles(params: { userId: string }) {
    return this.appService.get(`${APIConstant.role.getUserRoles}`, params);
  }

  deleteRoles(data: { roleNames: string[] }) {
    return this.appService.post(`${APIConstant.role.deleteRoles}`, data);
  }

  createRoles(data: { roleNames: string[] }) {
    return this.appService.post(`${APIConstant.role.createRoles}`, data);
  }

  assignRolesToUsers(data: { roleNames: string[]; userIds: string[] }) {
    return this.appService.post(`${APIConstant.role.assignRolesToUsers}`, data);
  }

  unassignRolesFromUsers(data: { roleNames: string[]; userIds: string[] }) {
    return this.appService.post(
      `${APIConstant.role.unassignRolesFromUsers}`,
      data
    );
  }
}
