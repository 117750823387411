import { createReducer, on } from '@ngrx/store';
import {
  setInvoiceModalStep,
  setStateAction,
  triggerInvoiceDownload,
} from '../actions/invoice.actions';
import { Action } from '../../models';

export interface InvoiceReducerInitialState {
  modalState: boolean;
  triggerDownload: boolean;
  step: number;
}

export const initialState: InvoiceReducerInitialState = {
  modalState: false,
  triggerDownload: false,
  step: 0,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  }),
  on(triggerInvoiceDownload, (state, payload) => {
    return {
      ...state,
      triggerDownload: payload.data,
    };
  }),
  on(setInvoiceModalStep, (state, payload) => {
    return {
      ...state,
      step:
        payload.event === 'increment'
          ? state.step + payload.data
          : payload.event === 'decrement'
          ? state.step - payload.data
          : payload.data,
    };
  })
);

export function invoiceReducer(
  state: InvoiceReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
