import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
// import { NzMessageModule } from 'ng-zorro-antd/message';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule, NzNotificationModule, SvgIconComponent],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.css',
})
export class NotificationComponent {
  //Services
  notificationService = inject(NzNotificationService);
  notificationId: string;

  @ViewChild('errorTemplate', { static: false })
  errorTemplate?: TemplateRef<{}>;
  @ViewChild('loadingTemplate', { static: true })
  loadingTemplate?: TemplateRef<any>;

  openLoadingNotification(loadingMessage: string): void {
    this.closeNotification();
    const ref = this.notificationService.template(this.loadingTemplate, {
      nzDuration: 0,
      nzPlacement: 'topRight',
      nzClass:
        'bg-[#2f3033] rounded-md flex gap-3 px-3 text-white item-center py-3',
      nzData: {
        content: loadingMessage,
      },
    });
    this.notificationId = ref.messageId;
  }
  openErrorNotification(errorMessage: string): void {
    this.closeNotification();
    const ref = this.notificationService.template(this.errorTemplate, {
      nzDuration: 7000,
      nzPlacement: 'topRight',
      nzClass:
        'bg-red-600 rounded-md flex gap-3 px-3 text-white item-center py-3',
      nzData: {
        content: errorMessage,
      },
    });
    this.notificationId = ref.messageId;
  }
  openSuccessNotification(successMessage: string): void {
    this.closeNotification();
    const ref = this.notificationService.template(this.errorTemplate, {
      nzDuration: 7000,
      nzPlacement: 'topRight',
      nzClass:
        'bg-[#00A251] rounded-md flex gap-3 px-3 text-white item-center py-3',
      nzData: {
        content: successMessage,
      },
    });
    this.notificationId = ref.messageId;
  }
  openInfoNotification(infoMessage: string): void {
    this.closeNotification();
    const ref = this.notificationService.template(this.errorTemplate, {
      nzDuration: 7000,
      nzPlacement: 'topRight',
      nzClass:
        'bg-[#3366ff] rounded-md flex gap-3 px-3 text-white item-center py-3',
      nzData: {
        content: infoMessage,
      },
    });
    this.notificationId = ref.messageId;
  }

  closeNotification(): void {
    if (!this.notificationId) {
      this.notificationService.remove();
      return;
    }
    this.notificationService.remove(this.notificationId);
    this.notificationId = '';
  }
}
