<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="left"
  nzTitle="Menu"
  (nzOnClose)="onClose()"
  [nzWidth]="360"
  [nzBodyStyle]="{
    'padding-left': 0,
    'padding-right': '1px',
    'padding-top': '0px',
    'padding-bottom': '20px',
  }"
>
  <ng-container *nzDrawerContent>
    <ng-scrollbar externalViewport appearance="compact" visibility="hover">
      <div scrollViewport [ngStyle]="{ height: 'calc(100vh - 56px)' }">
        <ul nz-menu nzMode="vertical">
          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Home</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/about-us']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>About Us</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/contact-us']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Contact Us</span>
            </a>
          </li>

          <li
            nz-submenu
            nzMatchRouterExact
            nzMatchRouter
            [nzTitle]="titleTemplate"
          >
            <ng-template #titleTemplate>
              <a
                [routerLink]="['/tax-trails']"
                class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
              >
                <span>Tax Trails</span>
              </a>
            </ng-template>

            <ng-scrollbar
              externalViewport
              appearance="compact"
              visibility="hover"
            >
              <div scrollViewport [ngStyle]="{ height: 'calc(60vh)' }">
                <ul>
                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'mva-kaduna']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>MVA Kaduna</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'mda-collection']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>MDA Collection</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'witholding']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Witholding</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'education']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Education</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'transportation']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Transportation</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'whatsapp-pay']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Whatsapp Pay</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'tax-benefit']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Tax Benefit</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'mining']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Mining</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'paye-manager']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>PAYE Manager</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'development-levy']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Development Levy</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="['/', 'tax-trails', 'consumption-tax']"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Consumption Tax</span>
                    </a>
                  </li>

                  <li nz-menu-item nzMatchRouterExact nzMatchRouter>
                    <a
                      [routerLink]="[
                        '/',
                        'tax-trails',
                        'business-premises-trade-fees'
                      ]"
                      class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
                    >
                      <span>Business Premises & Trade Fees</span>
                    </a>
                  </li>
                </ul>
              </div>
            </ng-scrollbar>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/what-we-offer']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>What we Offer</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/stamp-duty-capital-gain-tax']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Stamp Duty & Capital Gains Tax</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/tax-benefits']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Tax Benefits</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/communication-infrastructure-assessment-portal']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Communication Infrastructure Assessment Portal</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/gallery']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Gallery</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/news']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>News</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/e-services']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>E-Services</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/tax-calculator']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>Tax Calculator</span>
            </a>
          </li>

          <li nz-menu-item nzMatchRouterExact nzMatchRouter>
            <a
              [routerLink]="['/faq']"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <span>FAQ</span>
            </a>
          </li>
        </ul>

        <div class="p-6">
          <a
            class="flex items-center font-medium text-gray-900 title-font md:mb-0"
          >
            <img
              [src]="'assets/images/logo_with_name_alternate.png'"
              class="w-[130px] overflow-hidden object-fill"
            />
          </a>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
</nz-drawer>
