import * as CryptoJS from 'crypto-js';

const key = 'jdskfjdskhf848yr&^%$###kdkfcdfk';

export const encrypt = (txt: string): string => {
  return CryptoJS.AES.encrypt(txt, key).toString();
};

export const decrypt = (txtToDecrypt: string) => {
  return CryptoJS.AES.decrypt(txtToDecrypt, key).toString(CryptoJS.enc.Utf8);
};
