import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { confirmPasswordValidator } from '../../../validators/confirm-password-validator';
import { CommonModule } from '@angular/common';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import { PasswordInputComponent } from '../../../shared/widgets/inputs/password-input/password-input.component';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { SvgIconComponent } from 'angular-svg-icon';
import { customPasswordValidator } from '../../../validators/custom-password-validator';
import { AuthService } from '../../../core/services/auth.service';
import {
  IPreRegisterUserDataProps,
  IRegisterProps,
  IRegisterUserDataProps,
} from '../../../core/models';
import { injectMutation } from '@tanstack/angular-query-experimental';
import dayjs from 'dayjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    GeneralInputComponent,
    PasswordInputComponent,
    GeneralButtonDirective,
    SvgIconComponent,
  ],
  templateUrl: './create-password.component.html',
  styleUrl: './create-password.component.css',
})
export class CreatePasswordComponent {
  authService = inject(AuthService);
  router = inject(Router);

  @Input({ required: true }) signupData: IPreRegisterUserDataProps;

  authForm = new FormGroup(
    {
      password: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
    },
    {
      validators: [customPasswordValidator],
      updateOn: 'change',
    }
  );

  mutation = injectMutation((client) => ({
    mutationFn: (data: IRegisterProps) => this.authService.register(data),
    onSuccess: (data: any) => {
      this.router.navigate(['/email-notice'], {
        queryParams: {
          email: this.signupData.email,
        },
      });
    },
    onError: (err) => {
      console.log('err :>> ', err);
    },
  }));

  submitHandler() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    const data = {
      ...this.signupData,
      ...this.authForm.value,
    } as ObjectLiteral;
    const dob = dayjs(data.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.mutation.mutate({
      ...data,
      dateOfBirth: dob,
    } as any);
  }
}
