import { createReducer, on } from '@ngrx/store';
import { Action } from '../../models';
import * as toastActions from '../actions/toast.actions';
import { immerOn } from 'ngrx-immer/store';

export interface ToastState {
  options: {
    delay: number;
    placement: any;
    autohide: boolean;
    message?: any;
    title?: any;
  } | null;
  toastType: 'loading' | 'success' | 'error' | 'info' | 'clear';
}

export const initialToastState: ToastState = {
  options: null,
  toastType: 'info',
};

const reducer = createReducer(
  initialToastState,
  on(toastActions.loadingToastAction, (state, options) => ({
    ...state,
    toastType: 'loading' as ToastState['toastType'],
    options,
  })),
  on(toastActions.errorToastAction, (state, options) => ({
    ...state,
    toastType: 'error' as ToastState['toastType'],
    options,
  })),
  on(toastActions.infoToastAction, (state, options) => ({
    ...state,
    toastType: 'info' as ToastState['toastType'],
    options,
  })),
  on(toastActions.successToastAction, (state, options) => ({
    ...state,
    toastType: 'success' as ToastState['toastType'],
    options,
  })),
  on(toastActions.clearToastAction, (state) => ({
    ...state,
    toastType: 'clear' as ToastState['toastType'],
    options: null,
  }))
);

export function toastReducer(state: ToastState, action: Action) {
  return reducer(state, action);
}
