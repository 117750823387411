import { CommonModule, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { HeaderComponent } from '../header/header.component';
import { AuthService } from '../../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-coming-soon',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, HeaderComponent],
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.css',
})
export class ComingSoonComponent {
  authService = inject(AuthService);
  route = inject(ActivatedRoute);
  location = inject(Location);
  get abbr() {
    const firstName = (this.authService.userData()?.firstName as string) || '';
    const lastName = (this.authService.userData()?.lastName as string) || '';
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
  }
  get routePath() {
    return this.location.path();
  }
}
