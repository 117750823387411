import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LookupService } from '../services/lookup.service';
import {
  expertSystemsLookupAction,
  mdaLookupAction,
  paymentChannelsLookupAction,
  paymentGatewaysLookupAction,
  revenueHeadsLookupAction,
  setStateAction,
  taxOfficesLookupAction,
  taxPayerCategoriesLookupAction,
} from '../store/actions/lookup.actions';
import {
  getExpertSystems,
  getMda,
  getPaymentChannels,
  getPaymentGateways,
  getRevenueHeads,
  getTaxPayerCategories,
  getTaxoffices,
} from '../store/selectors/lookup.selectors';
import { errorToastAction } from '../store/actions/toast.actions';

@Injectable()
export class LookupEffects {
  constructor(
    private actions$: Actions,
    private lookupService: LookupService,
    private store: Store
  ) {}

  latestEsValue: any;
  latestRhValue: any;
  latestPcValue: any;
  latestMdaValue: any;
  latestTpcValue: any;
  latestToValue: any;

  loadExpertSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expertSystemsLookupAction),
      withLatestFrom(this.store.pipe(select(getExpertSystems))),
      switchMap(([action, expertSystemStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            expertSystems: { data: expertSystemStore.data, isLoading: true },
          })
        );

        return this.lookupService.getExpertSystemForEffect().pipe(
          map((response) =>
            setStateAction({
              expertSystems: {
                data: response,
                isLoading: expertSystemStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestEsValue = action.expertSystems.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching expert system data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                expertSystems: {
                  data: this.latestEsValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadRevenueHeads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revenueHeadsLookupAction),
      withLatestFrom(this.store.pipe(select(getRevenueHeads))),
      switchMap(([action, revenueHeadStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            revenueHeads: { data: revenueHeadStore.data, isLoading: true },
          })
        );

        return this.lookupService.getRevenueHeadsForEffect().pipe(
          map((response) =>
            setStateAction({
              revenueHeads: {
                data: response,
                isLoading: revenueHeadStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestRhValue = action.revenueHeads.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching revenue head data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                revenueHeads: {
                  data: this.latestRhValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadPaymentChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentChannelsLookupAction),
      withLatestFrom(this.store.pipe(select(getPaymentChannels))),
      switchMap(([action, paymentChannelStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            paymentChannels: {
              data: paymentChannelStore.data,
              isLoading: true,
            },
          })
        );

        return this.lookupService.getPaymentChannelsForEffect().pipe(
          map((response) =>
            setStateAction({
              paymentChannels: {
                data: response,
                isLoading: paymentChannelStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestPcValue = action.paymentChannels.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching payment channel data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                paymentChannels: {
                  data: this.latestPcValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadPaymentGateways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentGatewaysLookupAction),
      withLatestFrom(this.store.pipe(select(getPaymentGateways))),
      switchMap(([action, paymentGatewayStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            paymentGateways: {
              data: paymentGatewayStore.data,
              isLoading: true,
            },
          })
        );

        return this.lookupService.getPaymentGatewaysForEffect().pipe(
          map((response) =>
            setStateAction({
              paymentGateways: {
                data: response,
                isLoading: paymentGatewayStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestPcValue = action.paymentGateways.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching payment gateways data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                paymentGateways: {
                  data: this.latestPcValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadMda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mdaLookupAction),
      withLatestFrom(this.store.pipe(select(getMda))),
      switchMap(([action, mdaStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            mda: {
              data: mdaStore.data,
              isLoading: true,
            },
          })
        );

        return this.lookupService.getMdaForEffect().pipe(
          map((response) =>
            setStateAction({
              mda: {
                data: response,
                isLoading: mdaStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestMdaValue = action.mda.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching mda data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                mda: {
                  data: this.latestMdaValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadTaxPayerCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxPayerCategoriesLookupAction),
      withLatestFrom(this.store.pipe(select(getTaxPayerCategories))),
      switchMap(([action, taxPayerCategoriesStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            taxPayerCategories: {
              data: taxPayerCategoriesStore.data,
              isLoading: true,
            },
          })
        );

        return this.lookupService.getTaxPayerCategories().pipe(
          map((response) =>
            setStateAction({
              taxPayerCategories: {
                data: response,
                isLoading: taxPayerCategoriesStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestTpcValue = action.taxPayerCategories.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching tax payer categories data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                taxPayerCategories: {
                  data: this.latestTpcValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );

  loadTaxOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(taxOfficesLookupAction),
      withLatestFrom(this.store.pipe(select(getTaxoffices))),
      switchMap(([action, taxOfficesStore]) => {
        // set listLoading to true
        this.store.dispatch(
          setStateAction({
            taxOffices: {
              data: taxOfficesStore.data,
              isLoading: true,
            },
          })
        );

        return this.lookupService.getTaxOfficesForEffect().pipe(
          map((response) =>
            setStateAction({
              taxOffices: {
                data: response,
                isLoading: taxOfficesStore.isLoading,
              },
            })
          ),
          tap((action) => {
            this.latestToValue = action.taxOffices.data;
          }),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching tax offices data',
              })
            )
          ),
          finalize(() => {
            this.store.dispatch(
              setStateAction({
                taxOffices: {
                  data: this.latestToValue,
                  isLoading: false,
                },
              })
            );
          })
        );
      })
    )
  );
}
