import { Component, effect, inject, Input, signal } from '@angular/core';
import { HeaderComponent } from '../../../shared/widgets/header/header.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { LookupService } from '../../../core/services/lookup.service';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { CreatePasswordComponent } from '../create-password/create-password.component';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { errorToastAction } from '../../../core/store/actions/toast.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';

dayjs.extend(customParseFormat);

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    HeaderComponent,
    SvgIconComponent,
    CommonModule,
    GeneralInputComponent,
    NgSelectModule,
    GeneralButtonDirective,
    ReactiveFormsModule,
    CreatePasswordComponent,
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.css',
})
export class SignupComponent {
  steps = [
    {
      index: 1,
      data: {
        heading: 'Complete Registration',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
    {
      index: 2,
      data: {
        heading: 'Create password',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
    {
      index: 3,
      data: {
        heading: 'Verify Email',
        text: 'With your CAC account, you can register your company, file returns, and manage business info',
      },
    },
  ];
  lookupService = inject(LookupService);
  userService = inject(UserService);
  router = inject(Router);
  currentStep = signal(1);
  route = inject(ActivatedRoute);
  store = inject(Store<AppState>);
  preRegistrationData = null;
  @Input() n = '';
  ninSignal = signal('');

  authForm = new FormGroup(
    {
      //   userType: new FormControl(null, [Validators.required]),
      nin: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^\d{11}$/),
      ]),
      agency: new FormControl(null),
      agencyId: new FormControl(null),
      agentUid: new FormControl(null),
      agencyRcNumber: new FormControl(null),
      agencyTypeId: new FormControl(null),
      //   uid: new FormControl(null),
      agent_type: new FormControl(null),
      email: new FormControl(null, [Validators.email, Validators.required]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      middleName: new FormControl(null),
      userName: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [Validators.required]),
      dateOfBirth: new FormControl(null, [Validators.required]),
      genderId: new FormControl(null, [Validators.required]),
      occupation: new FormControl(null, [Validators.required]),
      nationality: new FormControl('Nigerian', [Validators.required]),
      stateId: new FormControl(null, [Validators.required]),
      lgaId: new FormControl(null, [Validators.required]),
      addressLine1: new FormControl(null, [Validators.required]),
      addressLine2: new FormControl(null),
      postalCode: new FormControl(null),
    }
    //   {
    //     validators: [confirmPasswordValidator],
    //   }
  );

  constructor() {
    effect(
      () => {
        const data = this.userPreRegistrationResponse.data();
        const isError = this.userPreRegistrationResponse.isError();
        const error = (this.userPreRegistrationResponse.error() as any)?.error
          ?.message;
        if (isError) {
          this.store.dispatch(
            errorToastAction({
              autohide: true,
              delay: 7000,
              placement: 'top-end',
              message:
                error ||
                'An error occurred. Refresh your browser and try again',
            })
          );
          return;
        }
        this.preRegistrationData = data;
        const userType = data?.userType;

        const userInfo = data?.user;
        const registrationInfo = data?.userRegistration;
        if (userType === 'Agent') {
          this.authForm.controls.agencyId.addValidators([Validators.required]);
          this.authForm.controls.agency.addValidators([Validators.required]);
          this.authForm.controls.agentUid.addValidators([Validators.required]);
          this.authForm.controls.agencyRcNumber.addValidators([
            Validators.required,
          ]);
          this.authForm.updateValueAndValidity();
        } else {
          this.authForm.controls.agencyId.removeValidators([
            Validators.required,
          ]);
          this.authForm.controls.agency.removeValidators([Validators.required]);
          this.authForm.controls.agentUid.removeValidators([
            Validators.required,
          ]);
          this.authForm.controls.agencyRcNumber.removeValidators([
            Validators.required,
          ]);
          this.authForm.updateValueAndValidity();
        }
        this.authForm.patchValue({
          firstName: userInfo?.firstName || null,
          middleName: userInfo?.middleName || null,
          lastName: userInfo?.lastName || null,
          email: userInfo?.email || null,
          phoneNumber: userInfo?.phoneNumber || null,
          userName: userInfo?.userName || null,
          nin: registrationInfo?.nin || null,
          ...(userType === 'Agent'
            ? {
                agencyId: registrationInfo?.agencyId || null,
                agency: registrationInfo?.agency?.name || '',
                agentUid: registrationInfo?.agentUid || '',
                agencyRcNumber: registrationInfo?.agencyRcNumber || '',
              }
            : {}),
          // agencyId: 1,
          // agency: {
          //   id: 1,
          //   name: 'Nigerian Bar Association',
          // },
          // agencyTypeId: 1,
          // agencyType: {
          //   id: 1,
          //   name: 'Individual',
          // },
          // agentUid: '12345',
          // agencyRcNumber: null,
          // createdAt: '2024-04-04T21:26:28.271999Z',
          // modifiedAt: '2024-04-04T21:26:28.271999Z',
        });
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    if (this.n) {
      this.ninSignal.set(this.n);
      sessionStorage.setItem('pay_kd_reg_nin', this.n);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: null,
      });
    } else {
      const ninFromStore = sessionStorage.getItem('pay_kd_reg_nin');
      if (!ninFromStore) {
        this.router.navigate(['/login']);
        return;
      }

      this.n = ninFromStore;
      this.ninSignal.set(ninFromStore);
    }
  }

  stateListQueryResponse = injectQuery(() => {
    return {
      queryKey: ['state-list'],
      queryFn: () => this.lookupService.stateLookup(),
    };
  });

  genderQueryResponse = injectQuery(() => {
    return {
      queryKey: ['gender-list'],
      queryFn: () => this.lookupService.genderLookup(),
    };
  });

  lgaQueryResponse = injectQuery(() => {
    return {
      queryKey: ['lga-list'],
      queryFn: () => this.lookupService.lgaLookup(),
    };
  });
  userPreRegistrationResponse = injectQuery(() => {
    const nin = this.ninSignal();
    return {
      queryKey: ['preregistration-data'],
      queryFn: () => this.userService.userPreregistrationData(nin),
      enabled: !!nin,
    };
  });

  submitHandler() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.currentStep.update((curr) => curr + 1);
  }
}
