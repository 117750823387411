import { Component, inject, Input, signal } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { HeaderComponent } from '../../../shared/widgets/header/header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationService } from '../../../core/services/verification.service';
import {
  CreateMutationResult,
  injectMutation,
} from '@tanstack/angular-query-experimental';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-email-verification-notice',
  standalone: true,
  imports: [LottieComponent, HeaderComponent, CommonModule],
  templateUrl: './email-verification-notice.component.html',
  styleUrl: './email-verification-notice.component.css',
})
export class EmailVerificationNoticeComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/email_lottie_purple.json',
  };

  route = inject(ActivatedRoute);
  verificationService = inject(VerificationService);
  router = inject(Router);
  @Input() email: string = '';
  emailSignal = signal('');

  resendEmailResponse = injectMutation((client) => ({
    mutationFn: (data: { email: string }) =>
      this.verificationService.sendVerificationEmail(data),
    onSuccess: (data) => {},
    onError: (err) => {
      console.log('err :>> ', err);
    },
  }));

  onAnimate(animationItem: AnimationItem): void {
    // console.log(animationItem);
  }
  ngOnInit(): void {
    if (this.email) {
      this.emailSignal.set(this.email);
      sessionStorage.setItem('pay_kd_reg_email', this.email);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: null,
      });
    } else {
      const emailFromStore = sessionStorage.getItem('pay_kd_reg_email');
      if (!emailFromStore) {
        this.router.navigate(['/login']);
        return;
      }

      this.email = emailFromStore;
      this.emailSignal.set(emailFromStore);
    }
  }
  resendEmail() {
    this.resendEmailResponse.mutate({ email: this.emailSignal() });
  }
}
