import { Component, ComponentRef, inject, ViewChild } from '@angular/core';
import {
  ToastObserverProps,
  ToastService,
} from '../../../core/services/toast.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { getToast } from '../../../core/store/selectors/toast.selectors';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, NotificationComponent],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
})
export class ToastCustomComponent {
  toastService = inject(ToastService);
  toastOptions: ToastObserverProps;
  delay = 7000;

  store = inject(Store<AppState>);

  @ViewChild(NotificationComponent) notification!: NotificationComponent;
  toastState: ObjectLiteral;
  componentRef: ComponentRef<any>;

  ngOnInit(): void {
    this.toastService.visibleSubject.subscribe({
      next: (val) => {
        this.toastOptions = val;
        // this.visible = val;
      },
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.store.select(getToast).subscribe((toastStore) => {
      this.toastState = toastStore;
      if (toastStore?.toastType === 'loading') {
        this.notification.openLoadingNotification(
          toastStore.options?.message || 'Loading. Please wait...'
        );
      }
      if (toastStore?.toastType === 'error') {
        this.notification.openErrorNotification(
          toastStore.options?.message ||
            'An error has occurred. Please contact support'
        );
      }
      if (toastStore?.toastType === 'success') {
        this.notification.openSuccessNotification(
          toastStore.options?.message || 'Operation successful'
        );
      }
      if (toastStore?.toastType === 'info' && toastStore.options) {
        this.notification.openInfoNotification(
          toastStore.options?.message || ''
        );
      }
      if (toastStore?.toastType === 'clear') {
        this.notification.notificationService.remove();
      }
    });
  }

  onVisibleChange($event: boolean) {
    this.toastService.onVisibleChange($event);
    // this.percentage = !this.visible ? 0 : this.percentage;
  }

  onTimerChange($event: number) {
    // this.percentage = $event * 25;
  }
}
