import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalService } from '../services/local.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const localService = inject(LocalService);
  const userData = localService.getData('pay_kd_user');
  const token = userData?.token;

  const clone = req.clone({
    ...(token
      ? {
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        }
      : {}),
  });
  return next(clone);
};
