<app-header [hideRoutes]="false"></app-header>
<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">

    <div [ngClass]="{'mt-[32px] ml-[93px]':true}">
        <button backButton class="flex items-center gap-2 pb-6">
            <svg-icon src="assets/svgs/back_arrow_icon.svg" [svgStyle]="{ 'width.px':12,'height.px':10 }"></svg-icon>
            Go back</button>
        <h5 class="text-xl font-bold text-[#292929] pb-2"></h5>

    </div>
    <div class="w-full max-w-[551px] mx-auto bg-[#033920] p-3 rounded-2xl">

        <div class="p-8 bg-white rounded-lg">

            <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="flex flex-col flex-1 w-full">
                <h5 [ngClass]="{'font-bold text-xl text-[#292929]': true}">Forgot password</h5>
                <p [ngClass]="{'text-[#5D5E5E] text-sm': true}">Enter your email to reset password</p>
                <div class="flex flex-col gap-6 pt-6 mb-16">

                    <div class="text-[#5D5E5E] flex flex-col relative">

                        <app-general-input label="Email" type="text" placeholder="Enter email" controlName="email"
                            [formGroup]="authForm"></app-general-input>
                        <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                            *ngIf="!!authForm.get('email').errors && (authForm.get('email').touched||authForm.get('email').dirty)">
                            <span *ngIf="authForm.get('email').errors?.required">email is required</span>
                            <span *ngIf="authForm.get('email').errors?.email">email is not valid</span>

                        </div>
                    </div>

                </div>
                <div class="mt-auto text-center">
                    <button [ngClass]="{'disabled:opacity-50': true}" appGeneralButton [disabled]="mutation.isPending()"
                        type="submit">{{mutation.isPending()?"Resetting password. Please\
                        wait...":"Reset password"}}</button>
                </div>

            </form>

        </div>


    </div>
</div>
