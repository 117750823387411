import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { ExpertSystemSidemenuComponent } from '../../shared/widgets/expert-system-sidemenu/expert-system-sidemenu.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/store';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../core/store/selectors/settings.selectors';
import { AdminHeaderComponent } from '../../shared/widgets/admin-header/admin-header.component';

@Component({
  selector: 'app-expert-system-layout',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    AdminHeaderComponent,
    NzLayoutModule,
    ExpertSystemSidemenuComponent,
  ],
  templateUrl: './expert-system.component.html',
  styleUrl: './expert-system.component.css',
})
export class ExpertSystemLayoutComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);

  settingsStoreSub: Subscription;

  isCollapsed = signal(false);

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.isCollapsed.set(settingsStore.sideMenuCollapsedState);
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
