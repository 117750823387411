import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-invoice-report.actions';
import { Action } from '../../models';

export interface Invoice {
  taxPayerName: string;
  mda: string;
  createdAt: string;
  modifiedAt: string;
  revenueHead: string;
  id: string;
  taxOffice: string;
  invoiceNumber: string;
  amount: string;
}

export interface InvoiceReportReducerInitialState {
  // pagination
  page: number;
  total: number;
  size: number;
  // sort
  sortOrder: string;
  sortField: string;
  // data
  listLoading: boolean;
  list: Invoice[] | null;
  docLoading: boolean;
  doc: Invoice | null;
  cardsLoading: boolean;
  cards: any;
  // filters
  search: string;
  maxDate: string;
  minDate: string;
  status: string;
  rhId: string;
}

export const initialState: InvoiceReportReducerInitialState = {
  page: 1,
  total: 0,
  size: 50,
  sortOrder: '',
  sortField: '',
  search: '',
  maxDate: '',
  minDate: '',
  status: '',
  rhId: '',
  listLoading: false,
  list: null,
  docLoading: false,
  doc: null,
  cardsLoading: false,
  cards: null,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function invoiceReportReducerReducer(
  state: InvoiceReportReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
