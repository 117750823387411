import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';
import {
  resetQueryAction,
  setQueryAction,
  setStateAction,
} from '../store/actions/admin-mda-report.actions';
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import { Store } from '@ngrx/store';

@Injectable()
export class AdminMdaReportEffects {
  constructor(private actions$: Actions, private store: Store) {}

  queryClient = injectQueryClient();

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => {
        this.store.dispatch(setStateAction({ ...payload }));

        return { type: 'Success', successAction: payload.successAction };
      })
      // delay(100),
      // tap(({ successAction }) => {
      //   if (successAction) {
      //     successAction(this.queryClient);
      //   }
      // })
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({
            minDate: '',
            maxDate: '',
          })
        );

        return { type: 'Success', successAction: payload.successAction };
      })
      // delay(100),
      // tap(({ successAction }) => {
      //   if (successAction) {
      //     successAction(this.queryClient);
      //   }
      // })
    )
  );
}
