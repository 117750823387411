<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">
    <app-header [hideRoutes]="false"></app-header>
    <div class="pt-10">

        <div class="w-[153px] h-[153px] mx-auto" *ngIf="isLoading">
            <ng-lottie [options]="options" (animationCreated)="onAnimate($event)">
            </ng-lottie>
        </div>
        <div class="w-[153px] h-[153px] mx-auto" *ngIf="verifyEmailResponse.isSuccess()">
            <svg-icon src="assets/svgs/email_verified_banner.svg"
                [svgStyle]="{ 'width.px':153,'height.px':153 }"></svg-icon>
        </div>
        <div class="w-[153px] h-[153px] mx-auto" *ngIf="verifyEmailResponse.isError()">
            <svg-icon src="assets/svgs/cancel-lottie-icon.svg"
                [svgStyle]="{ 'width.px':153,'height.px':153 }"></svg-icon>
        </div>
        <div class="flex flex-col items-center gap-4 pt-10 text-center">
            <h5 class="text-black text-[32px] leading-[38px] font-semibold">
                {{verifyEmailResponse.isPending()?'Verifying email':verifyEmailResponse.isSuccess()?'Email\
                Verified':'Oops! We Could not Verify Your Email'}}</h5>
            <div class="text-center text-[#5D5E5E] text-lg font-semibold">
                <p class="text-[#5D5E5E]">{{verifyEmailResponse.isPending()?'Verifying your account. Please\
                    wait...':verifyEmailResponse.isSuccess()?'You have successfully verified your account.':'
                    Your email verification failed'}}</p>
            </div>
            <div class="text-center text-[#5D5E5E] text-lg pt-4">
                <button
                    [ngClass]="{'p-[10px] text-base bg-tw-primary text-white rounded-lg font-semibold disabled:opacity-50 px-6': true,}"
                    (click)="resendEmail()" [disabled]="resendEmailResponse.isPending()"
                    *ngIf="verifyEmailResponse.isError()">Resend verification email</button>
                <button
                    [ngClass]="{'p-[10px] text-base bg-tw-primary text-white w-[176px] rounded-lg font-semibold disabled:opacity-50': true}"
                    (click)="switchToLogin()" *ngIf="verifyEmailResponse.isSuccess()">Continue to login</button>
            </div>
        </div>

    </div>

</div>
