<ng-scrollbar externalViewport appearance="compact" visibility="hover">
  <div scrollViewport [ngStyle]="{ height: 'calc(100vh - 66px)' }">
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      @for (nav of menuList(); track $index) { @if (nav.subMenuList &&
      nav.subMenuList.length > 0) {
      <li
        nz-submenu
        [nzTitle]="titleTemplate"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? nav.name : ''"
      >
        <ng-template #titleTemplate>
          <a class="flex gap-3 items-center min-w-[40px] min-h-[40px]">
            <svg-icon [src]="nav.icon"></svg-icon>
            <span>{{ nav.name }}</span>
          </a>
        </ng-template>

        <ul>
          @for(item of nav.subMenuList; track $index) {
          <li nz-menu-item [nzMatchRouterExact]="item.exact" nzMatchRouter>
            <a
              [routerLink]="item.link"
              class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
            >
              <svg-icon [src]="item.icon"></svg-icon>
              <span>{{ item.name }}</span>
            </a>
          </li>
          }
        </ul>
      </li>
      } @else {
      <li
        nz-menu-item
        [nzDanger]="nav.type === 'Danger'"
        [nzMatchRouterExact]="nav.exact"
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? nav.name : ''"
      >
        <a
          [routerLink]="nav.link"
          class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
        >
          <svg-icon [src]="nav.icon"></svg-icon>
          <span>{{ nav.name }}</span>
        </a>
      </li>
      } }
    </ul>
  </div>
</ng-scrollbar>
