<nz-layout class="h-[100vh]">
  <nz-sider
    nzTheme="light"
    nzCollapsible
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
    nzWidth="250px"
    class="border-r"
  >
    <div class="h-[66px] flex items-center pl-[13px]">
      <a
        class="flex items-center font-medium text-gray-900 title-font md:mb-0"
        routerLink="/"
        nz-tooltip
        nzTooltipPlacement="right"
        nzTooltipTitle="Home"
      >
        <img
          *ngIf="!isCollapsed"
          [src]="'assets/images/logo_with_name.png'"
          class="w-[130px] rounded-full overflow-hidden object-fill"
        />
        <img
          *ngIf="isCollapsed"
          [src]="'assets/images/icon-logo.png'"
          class="w-[50px] rounded-full overflow-hidden object-fill"
        />
      </a>
    </div>
    <app-expert-system-sidemenu
      [isCollapsed]="isCollapsed()"
    ></app-expert-system-sidemenu>
  </nz-sider>

  <nz-layout>
    <nz-header class="border-b h-[66px]">
      <app-admin-header slug="expert-system"></app-admin-header>
    </nz-header>

    <nz-content class="p-10 overflow-y-auto">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
