import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from '../../shared/widgets/header/header.component';
import { RegisterComponent } from './register/register.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import { ToastCustomComponent } from '../../shared/widgets/toast/toast.component';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css',
  standalone: true,
  providers: [ToastService],
  imports: [
    RouterModule,
    LoginComponent,
    HeaderComponent,
    RegisterComponent,
    SvgIconComponent,
    NgOptimizedImage,
    CommonModule,
    ToastCustomComponent,
  ],
  host: {
    class: 'h-dvh block',
  },
})
export class AuthComponent implements OnInit {
  currentAuthForm = 'login';
  authForm: FormGroup;
  router = inject(Router);
  location = inject(Location);

  goBack() {
    this.location.back();
  }

  ngOnInit(): void {
    this.authForm = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
    });
  }

  get currentPath(): string {
    return this.router.url;
  }

  switchForms(formType: 'login' | 'register') {
    this.currentAuthForm = formType;
  }
}
