import { createAction, props } from '@ngrx/store';
import { ObjectLiteral } from '../../models/ObjectLiteral';
import { Action } from '../../models';

const prefix = '[Auth Route]';

export const changePasswordAction = createAction(
  `${prefix} change password`,
  props<{
    setLoading?: (val: boolean) => void;
    onSuccess?: () => void;
    oldPassword: string;
    password: string;
    confirmPassword: string;
  }>()
);
