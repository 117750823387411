<ng-scrollbar externalViewport appearance="compact" visibility="hover">
  <div scrollViewport [ngStyle]="{ height: 'calc(100vh - 66px)' }">
    <div class="flex flex-col justify-between h-[90vh] gap-10">
      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        @for (nav of menuList(); track $index) {
        <li
          nz-menu-item
          [nzDanger]="nav.type === 'Danger'"
          [nzMatchRouterExact]="nav.exact"
          nzMatchRouter
          nz-tooltip
          nzTooltipPlacement="right"
          [nzTooltipTitle]="isCollapsed ? nav.name : ''"
          [ngClass]="{
            '!mt-auto':
              menuList().length - 1 === $index && nav.type === 'Danger'
          }"
        >
          <a
            [routerLink]="nav.link"
            class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
          >
            <svg-icon [src]="nav.icon"></svg-icon>
            <span>{{ nav.name }}</span>
          </a>
        </li>
        }
      </ul>

      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        @for (nav of menuList2(); track $index) {
        <li
          nz-menu-item
          [nzDanger]="nav.type === 'Danger'"
          [nzMatchRouterExact]="nav.exact"
          nzMatchRouter
          nz-tooltip
          nzTooltipPlacement="right"
          [nzTooltipTitle]="isCollapsed ? nav.name : ''"
          [ngClass]="{
            '!mt-auto':
              menuList().length - 1 === $index && nav.type === 'Danger'
          }"
        >
          <a
            [routerLink]="nav.link"
            class="flex gap-3 items-center min-w-[40px] min-h-[40px]"
          >
            <svg-icon [src]="nav.icon"></svg-icon>
            <span>{{ nav.name }}</span>
          </a>
        </li>
        }
      </ul>
    </div>
  </div>
</ng-scrollbar>
