import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';

const menuList = [
  {
    name: 'Dashboard',
    link: ['/', 'gov_dashboard'],
    type: 'All',
    exact: true,
    icon: 'assets/svgs/menu/dashboard.svg',
  },
  {
    name: 'Analytics',
    link: ['/', 'gov_dashboard', 'analytics'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/analytic.svg',
  },
  {
    name: 'Reports',
    subMenuList: [
      {
        name: 'Invoice Reports',
        link: ['/', 'gov_dashboard', 'invoice-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
      {
        name: 'Collection Reports',
        link: ['/', 'gov_dashboard', 'collection-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
      {
        name: 'Settlement Reports',
        link: ['/', 'gov_dashboard', 'settlement-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
      },
    ],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/report.svg',
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
  },
];

@Component({
  selector: 'app-governor-sidemenu',
  standalone: true,
  imports: [
    SvgIconComponent,
    NzToolTipModule,
    RouterModule,
    NgScrollbarModule,
    CommonModule,
    NzMenuModule,
  ],
  templateUrl: './governor-sidemenu.component.html',
  styleUrl: './governor-sidemenu.component.css',
})
export class GovernorSidemenuComponent {
  @Input({ required: true }) isCollapsed = false;

  menuList = signal(menuList);
}
