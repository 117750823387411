<div>
    <h3 [ngClass]="{'flex items-center justify-center text-2xl font-semibold py-4 mb-16': true}">Chart types</h3>
    <router-outlet></router-outlet>


    <!-- <div [ngClass]="{'flex': true}">
        <div [ngClass]="{'flex-1 shrink-0': true}">
            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
                [title]="chartOptions.title" [legend]="chartOptions.legend" [noData]="chartOptions.noData"
                #chart></apx-chart>

        </div>
        <div [ngClass]="{'flex-1 shrink-0': true}">
            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
                [title]="chartOptions.title" [legend]="chartOptions.legend" [noData]="chartOptions.noData"
                [yaxis]="chartOptions.yaxis"></apx-chart>
        </div>

    </div> -->
</div>
