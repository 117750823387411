<app-header [hideRoutes]="false"></app-header>
<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">
    <div [ngClass]="{'px-[98px]':true}">
        <div class="flex items-center justify-between pt-3 pb-2">
            <p class="bg-[#EDFFF2] flex gap-2 p-[10px] rounded-[50px] items-center">
                <svg-icon src="assets/svgs/menu-dots.svg" [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                <span class="font-semibold text-text-color">Dashboard</span>
            </p>
            <p class="flex items-center gap-2">
                <span class="text-[#292929] text-sm font-medium">
                    {{ authService.userData()?.firstName }} {{ authService.userData()?.lastName}}
                </span>
                <span class="text-tw-primary font-medium text-base p-3 rounded-full bg-[#EDFFF2]">{{abbr}}</span>
            </p>

        </div>
    </div>
    <div [ngClass]="{'flex-1 flex justify-center items-center': true}">
        <div [ngClass]="{'flex flex-col items-center mt-[-100px]': true}">
            <div>
                <svg-icon src="assets/svgs/coming_soon_icon.svg"
                    [svgStyle]="{ 'width.px':133,'height.px':133 }"></svg-icon>

            </div>
            <div [ngClass]="{'text-2xl font-semibold': true}">{{routePath==='/sadmin'?'Super admin':'Admin'}} feature
                coming soon</div>

        </div>
    </div>

</div>
