import { Component, computed, inject, Input } from '@angular/core';
import {
  ReceiptComponent,
  ReceiptData,
} from '../../user-protected/tax-payment/receipt/receipt.component';
import { CommonModule } from '@angular/common';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { BillsService } from '../../../core/services/bills.service';

@Component({
  selector: 'app-receipt-verification',
  standalone: true,
  imports: [ReceiptComponent, CommonModule],
  templateUrl: './receipt-verification.component.html',
  styleUrl: './receipt-verification.component.css',
})
export class ReceiptVerificationComponent {
  billService = inject(BillsService);

  @Input() ref;

  billQueryResponse = injectQuery(() => {
    return {
      queryKey: ['single-bill', this.ref],
      queryFn: ({ queryKey }) => {
        const [, billRef] = queryKey;
        return this.billService.getBill({
          billReference: billRef,
        });
      },
      enabled: !!this.ref,
      retry: 1,
    };
  });

  isLoading = computed(() => {
    return (
      this.billQueryResponse.fetchStatus() === 'fetching' &&
      this.billQueryResponse.isPending()
    );
  });

  receiptData = computed<ReceiptData>(() => {
    const bill = this.billQueryResponse.data() as ObjectLiteral;
    const userData = this.billQueryResponse.data()?.userData?.user;
    const userAddress =
      this.billQueryResponse.data()?.userData?.userRegistration?.addressLine1;
    return {
      ...bill,
      billTo: {
        name: `${userData?.firstName} ${userData?.lastName}`,
        address: userAddress || '',
        email: `${userData?.email}`,
      },
      billFrom: {
        name: 'Kaduna State Internal Revenue Service',
        address: 'Kaduna State',
      },
      items: (bill?.bill?.billDetails || []).map((item) => ({
        amount: item?.amount || 0,
        rate: item?.amount || 0,
        description: item?.narration,
        quantity: 1,
      })),
      totalAmount:
        (bill?.bill?.billDetails || []).reduce((a, b) => a + b.amount, 0) || 0,
    } as ReceiptData;
  });
}
