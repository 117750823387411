<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">
    <app-header [hideRoutes]="false"></app-header>
    <div class="pl-10 text-start pb-9">
        <p
            class="text-[#2e2409] text-sm bg-[#FFF9EB] w-full max-w-[769px] py-2 px-3 flex items-start justify-start gap-2 font-normal mt-[51px]">
            <svg-icon src="assets/svgs/exclamation_circled.svg"
                [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
            <span>Any member of the public
                can create
                an account, kindly
                note you will
                be a
                director, trustee or a
                proprietor/partner
                during the registration process. Please provide the necessary details.</span>
        </p>
    </div>
    <div class="flex pt-20 signup-container">
        <div class="flex flex-col pl-10 stepper h-[447px] flex-1 max-w-[500px] border-r-[1.2px] border-[#E1E1E1]">
            @for (step of steps; track $index) {
            <div [ngClass]="{'flex gap-[19px]':true,'flex-1':$index!==steps.length-1}">
                <div class="flex flex-col items-center py-1">
                    <span [ngClass]="{'stepper-span flex rounded-full w-11 h-11 min-w-[44px] min-h-[44px] border border-[#e1e1e1] justify-center items-center [&_svg_path]:fill-black flex-col':true,'bg-tw-primary [&_svg_path]:fill-white border-tw-primary':currentStep()>=step.index,'mb-1':$index!==steps.length-1
                    }">
                        <svg-icon
                            [src]="currentStep()>step.index?'assets/svgs/check.svg':step.index===1?'assets/svgs/user.svg':step.index===2?'assets/svgs/lock.svg':'assets/svgs/mail-open.svg'"
                            [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
                    </span>
                    <span *ngIf="$index!==steps.length-1"
                        [ngClass]="{'!bg-tw-primary': currentStep()>step.index}"></span>

                </div>
                <div class="flex gap-2">
                    <p class="whitespace-nowrap text-[#5D5E5E]">Step {{step.index}}</p>
                    <div class="flex flex-col gap-[10px]">
                        <h3 class="text-[#000] text-lg font-semibold">{{step.data.heading}}</h3>
                        <p class="text-[#5D5E5E] text-sm">{{step.data.text}}</p>
                    </div>

                </div>
            </div>
            }
        </div>
        <div class="flex-1 pl-12 pr-10">
            @if (currentStep()===1) {
            <div>
                <h3 class="text-[#000] text-2xl font-semibold pb-9">Sign up</h3>
                <div>
                    <form class="w-full max-w-[611px] flex flex-col gap-[19px]" (ngSubmit)="submitHandler()"
                        [formGroup]="authForm">
                        <div [ngClass]="{'flex w-full gap-4': true,'hidden':preRegistrationData?.userType!=='Agent'}">
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Agency" type="text" placeholder="Agency" controlName="agency"
                                    [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('agency').errors && (authForm.get('agency').touched||authForm.get('agency').dirty)">
                                    <span *ngIf="authForm.get('agencyId').errors?.required">Field is required</span>

                                </div>
                            </div>

                        </div>
                        <div [ngClass]="{'flex w-full gap-4': true,'hidden':preRegistrationData?.userType!=='Agent'}">
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="RC Number" type="text" placeholder="RC Number"
                                    controlName="agencyRcNumber" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('agencyRcNumber').errors && (authForm.get('agencyRcNumber').touched||authForm.get('agencyRcNumber').dirty)">
                                    <span *ngIf="authForm.get('agencyRcNumber').errors?.required">Field is
                                        required</span>

                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Agency ID/Number" type="text" placeholder="Agency ID/Number"
                                    controlName="agentUid" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('agentUid').errors && (authForm.get('agentUid').touched||authForm.get('agentUid').dirty)">
                                    <span *ngIf="authForm.get('agentUid').errors?.required">Field is required</span>

                                </div>
                            </div>

                        </div>
                        <div class="flex w-full gap-4">

                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Enter username" type="text" placeholder="Enter username"
                                    controlName="userName" [formGroup]="authForm" [disabled]="true"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('userName').errors && (authForm.get('userName').touched||authForm.get('userName').dirty)">
                                    <span *ngIf="authForm.get('userName').errors?.required">Username is required</span>
                                    <span *ngIf="authForm.get('userName').errors?.pattern">Unique identifier must be 11
                                        digits.</span>
                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Enter NIN" type="text" placeholder="Enter NIN"
                                    controlName="nin" [formGroup]="authForm" [disabled]="true"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('nin').errors && (authForm.get('nin').touched||authForm.get('nin').dirty)">
                                    <span *ngIf="authForm.get('nin').errors?.required">Field is required</span>
                                    <span *ngIf="authForm.get('nin').errors?.pattern">Unique identifier must be 11
                                        digits.</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-4">
                            <div class="text-[#5D5E5E] flex flex-col relative">

                                <app-general-input label="First name" type="text" placeholder="First name"
                                    controlName="firstName" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('firstName').errors && (authForm.get('firstName').touched||authForm.get('firstName').dirty)">
                                    <span *ngIf="authForm.get('firstName').errors?.required">Field is required</span>

                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative">

                                <app-general-input label="Last name" type="text" placeholder="Enter last name"
                                    controlName="lastName" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('lastName').errors && (authForm.get('lastName').touched||authForm.get('lastName').dirty)">
                                    <span *ngIf="authForm.get('lastName').errors?.required">Field is required</span>

                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative">

                                <app-general-input label="Other name" type="text" placeholder="Other name"
                                    controlName="middleName" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('middleName').errors && (authForm.get('middleName').touched||authForm.get('middleName').dirty)">
                                    <span *ngIf="authForm.get('middleName').errors?.required">Field is required</span>

                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-4">

                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Email" type="text" placeholder="Email" controlName="email"
                                    [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('email').errors && (authForm.get('email').touched||authForm.get('email').dirty)">
                                    <span *ngIf="authForm.get('email').errors?.required">Field is required</span>
                                    <span *ngIf="authForm.get('email').errors?.email">Email is not valid</span>

                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Date of Birth" type="text" placeholder="DD/MM/YYYY"
                                    controlName="dateOfBirth" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('dateOfBirth').errors && (authForm.get('dateOfBirth').touched||authForm.get('dateOfBirth').dirty)">
                                    <span *ngIf="authForm.get('dateOfBirth').errors?.required">Field is required</span>

                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-4">
                            <div class="text-[#5D5E5E] flex gap-2 flex-col relative flex-1">
                                <label class="text-sm font-medium">Gender</label>
                                <ng-select placeholder="Gender" class="custom" formControlName="genderId"
                                    [loading]="genderQueryResponse.isPending()">
                                    @for (gender of genderQueryResponse.data(); track gender.id) {
                                    <ng-option [value]="gender.id">{{gender.displayField}}</ng-option>

                                    }
                                </ng-select>

                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('genderId').errors && authForm.get('genderId').touched">
                                    <span *ngIf="authForm.get('genderId').errors?.required">Field is required</span>
                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Occupation" type="text" placeholder="Occupation"
                                    controlName="occupation" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('occupation').errors && (authForm.get('occupation').touched||authForm.get('occupation').dirty)">
                                    <span *ngIf="authForm.get('occupation').errors?.required">Field is required</span>

                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-4">

                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Nationality" type="text" placeholder="Nationality"
                                    controlName="nationality" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('nationality').errors && (authForm.get('nationality').touched||authForm.get('nationality').dirty)">
                                    <span *ngIf="authForm.get('nationality').errors?.required">Field is required</span>


                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex gap-2 flex-col relative flex-1">
                                <label class="text-sm font-medium">State</label>
                                <ng-select placeholder="State" class="custom" formControlName="stateId"
                                    [loading]="stateListQueryResponse.isPending()">
                                    @for (state of stateListQueryResponse.data(); track state.id) {
                                    <ng-option [value]="state.id">{{state.displayField}}</ng-option>

                                    }
                                </ng-select>

                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('stateId').errors && authForm.get('stateId').touched">
                                    <span *ngIf="authForm.get('stateId').errors?.required">Field is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-4">
                            <div class="text-[#5D5E5E] flex gap-2 flex-col relative flex-1">
                                <label class="text-sm font-medium">City</label>
                                <ng-select placeholder="City" class="custom" formControlName="lgaId"
                                    [loading]="lgaQueryResponse.isPending()">
                                    @for (lga of lgaQueryResponse.data(); track lga.id) {
                                    <ng-option [value]="lga.id">{{lga.displayField}}</ng-option>

                                    }
                                </ng-select>

                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('lgaId').errors && authForm.get('lgaId').touched">
                                    <span *ngIf="authForm.get('lgaId').errors?.required">Field is required</span>
                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Address 1" type="text" placeholder="Address 1"
                                    controlName="addressLine1" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('addressLine1').errors && (authForm.get('addressLine1').touched||authForm.get('addressLine1').dirty)">
                                    <span *ngIf="authForm.get('addressLine1').errors?.required">Field is required</span>


                                </div>
                            </div>

                        </div>
                        <div class="flex w-full gap-4">
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Address 2 (Optional)" type="text" placeholder="Address 2"
                                    controlName="addressLine2" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('addressLine2').errors && (authForm.get('addressLine2').touched||authForm.get('addressLine2').dirty)">
                                    <span *ngIf="authForm.get('addressLine2').errors?.required">Field is required</span>


                                </div>
                            </div>
                            <div class="text-[#5D5E5E] flex flex-col relative flex-1">

                                <app-general-input label="Postal code" type="text" placeholder="Postal code"
                                    controlName="postalCode" [formGroup]="authForm"></app-general-input>
                                <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                                    *ngIf="!!authForm.get('postalCode').errors && (authForm.get('postalCode').touched||authForm.get('postalCode').dirty)">
                                    <span *ngIf="authForm.get('postalCode').errors?.required">Field is required</span>


                                </div>
                            </div>

                        </div>
                        <div class="w-full mt-9">
                            <button [ngClass]="{'opacity-50': authForm.invalid,'block ml-auto max-w-[176px]':true}"
                                appGeneralButton type="submit">Next</button>
                        </div>
                    </form>
                </div>

            </div>

            }@else {
            <ng-container *ngTemplateOutlet="createPassword; context:{data:authForm.value}"></ng-container>

            }
        </div>
    </div>
</div>




<ng-template #createPassword let-signupData="data">
    <app-create-password [signupData]="signupData"></app-create-password>
</ng-template>
