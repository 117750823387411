import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-expert-system-module.actions';
import { Action } from '../../models';

export interface ExpertSystemModuleReducerInitialState {
  maxDate: string;
  minDate: string;
  esId: string;
}

export const initialState: ExpertSystemModuleReducerInitialState = {
  maxDate: '',
  minDate: '',
  esId: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function expertSystemModuleReducerReducer(
  state: ExpertSystemModuleReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
