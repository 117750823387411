<div [ngClass]="{ 'w-full bg-img flex flex-col flex-1': true }">
  <div [ngClass]="{ '!hidden': !!comingSoon, 'flex-1': true }">
    <app-header></app-header>
    <app-public-side-drawer></app-public-side-drawer>
    <router-outlet></router-outlet>
  </div>
  @if (comingSoon) {
  <ng-container *ngTemplateOutlet="coming_soon"></ng-container>
  }
</div>

<ng-template #coming_soon>
  <app-coming-soon></app-coming-soon>
</ng-template>
