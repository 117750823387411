import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ObjectLiteral } from '../models/ObjectLiteral';

export interface ToastObserverProps {
  visible: boolean;
  position: string;
  autoHide?: boolean;
  toastMessage: string;
  toastTitle: string;
  type: 'error' | 'success' | 'loading' | 'info';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  visibleSubject = new BehaviorSubject<ToastObserverProps>({
    visible: false,
    position: 'top-end',
    // autoHide: true,
    toastMessage: '',
    toastTitle: '',
    type: 'info',
  });
  $visible = this.visibleSubject.asObservable();

  toggleToast() {
    this.visibleSubject.next({
      ...this.visibleSubject.value,
      visible: true,
    });
  }
  showToast({
    title,
    message,
    ...others
  }: {
    title: string;
    message: string;
    [key: string]: string | number | boolean;
  }) {
    const { type } = others;
    this.visibleSubject.next({
      ...this.visibleSubject.value,
      visible: true,
      toastTitle: title,
      toastMessage: message,
      ...others,
      ...(type === 'loading' ? { autoHide: false } : {}),
    });
  }
  hideToast() {
    this.visibleSubject.next({
      visible: false,
      toastTitle: '',
      toastMessage: '',
      position: 'top-end',
      type: 'info',
    });
  }

  addToast(Comp: any, option: ObjectLiteral) {}

  onVisibleChange($event: boolean) {
    //   this.visible = $event;
    // this.visible.set($event);
    // this.percentage = !this.visible ? 0 : this.percentage;
  }
}
