import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { HeaderComponent } from '../../../shared/widgets/header/header.component';
import { BackButtonDirective } from '../../../shared/directives/buttons/back-button.directive';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../../shared/directives/inputs/general-input.directive';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { AuthService } from '../../../core/services/auth.service';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import {
  errorToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    HeaderComponent,
    BackButtonDirective,
    SvgIconComponent,
    GeneralInputComponent,
    GeneralInputDirective,
    GeneralButtonDirective,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css',
})
export class ForgotPasswordComponent {
  authForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });
  authService = inject(AuthService);
  store = inject(Store<AppState>);

  mutation = injectMutation((client) => ({
    mutationFn: (data: { email: string }) =>
      this.authService.resetPasswordRequest(data),
    onSuccess: (data) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Check your inbox for a password reset email',
        })
      );
      this.authForm.reset();
    },
    onError: (err) => {
      console.log('err :>> ', err);
      const error = (err as any)?.error?.message;
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: error || 'Confirm you have the right email',
        })
      );
    },
  }));

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.mutation.mutate({ email: this.authForm.value.email });
  }
}

//https://www.gxtesting.net/reset_password?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNlYmFzdGluZWNoaW1hQGdtYWlsLmNvbSIsInRva2VuIjoiQ2ZESjhIZzFyY2ZCRkhoQm9KVG1uaWlzbU1NQWZIUGs2cGcvY3pLWW5rM2UrNGNUUlI0T1Z2NmF5eDdMNnNGU1N0a2w0RFdiTEpvdjBhRmFSbDZZbmZ4UFIxN1U4SEU2T3VUTnNOYm9KYkNZY2dwdW9oajY5cHFYUEUyczRZMHdvMHN6V1Qrd0x2MExiazE4ZkNBb2dHWEtWOE1xZGRJNzNZVEZidlZ1YVkzWTZFVlIwaEg2cDR5NkdzVnUvMVB0SVcrV2pmWUIvSzY0cmhjWG0yVmQ4NExKVXBtNjJsMjc4ZFhhS0FwcGpMS292MXBtIiwibmJmIjoxNzEzOTMyMzg1LCJleHAiOjE3MTM5MzU5ODUsImlhdCI6MTcxMzkzMjM4NX0.BfNGQQVWyCJq2XimognmuzILLpos8QrYWnRmtFR5vx0
