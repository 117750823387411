<div class="w-full max-w-[1440px] mx-auto h-full flex flex-col overflow-auto pb-20">
    <app-header [hideRoutes]="false"></app-header>
    <div class="w-[153px] h-[153px] mx-auto">
        <ng-lottie [options]="options" (animationCreated)="onAnimate($event)">
        </ng-lottie>
    </div>
    <div class="flex flex-col items-center gap-4 pt-10 text-center">
        <h5 class="text-black text-[32px] leading-[38px] font-semibold">Email Verification Sent</h5>
        <div class="text-center text-[#5D5E5E] text-lg font-semibold">
            <p class="text-[#5D5E5E]">A verification email has been sent to your email address {{emailSignal()}}.</p>
            <p>Click
                the link in your
                email to verify
                your account</p>
        </div>
        <div class="text-center text-[#5D5E5E] text-lg pt-12">
            <p>
                Did not receive the code? <button [ngClass]="{'font-semibold text-tw-primary':true,
                'disabled:opacity-50 disabled:text-slate-400':resendEmailResponse.isPending()
            }" (click)="resendEmail()" [disabled]="resendEmailResponse.isPending()">Resend</button>
            </p>
        </div>
    </div>

</div>
