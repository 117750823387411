import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/role.actions';
import { Action } from '../../models';

export interface Role {
  name: string;
  description: string;
  permissions: any[];
  createdAt: string;
  modifiedAt: string;
  users: any;
  id: string;
}

export interface RoleReducerInitialState {
  page: number;
  total: number;
  size: number;
  sortOrder: string;
  sortField: string;
  search: string;
  listLoading: boolean;
  list: Role[] | null;
  docLoading: boolean;
  doc: Role | null;
}

export const initialState: RoleReducerInitialState = {
  page: 1,
  total: 0,
  size: 10,
  sortOrder: 'ascend',
  sortField: 'name',
  search: '',
  listLoading: false,
  list: null,
  docLoading: false,
  doc: null,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function roleReducer(state: RoleReducerInitialState, action: Action) {
  return reducer(state, action);
}
