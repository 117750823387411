import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState } from '..';
import { ToastState } from '../reducers/toast.reducer';

// export const isLoggedIn = createSelector(
//   ((state) => state['auth'],
//   (state) => state['lgas'],
//   ([auth, lga]) => [auth, lga])
// );

const getToastState = createFeatureSelector<ToastState>('toast');

export const getToast = createSelector(getToastState, (state) => {
  return state;
});
