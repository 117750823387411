import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HeaderComponent } from '../../../shared/widgets/header/header.component';
import { BackButtonDirective } from '../../../shared/directives/buttons/back-button.directive';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../../shared/directives/inputs/general-input.directive';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { customPasswordValidator } from '../../../validators/custom-password-validator';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { AuthService } from '../../../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import {
  errorToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';
import { Router } from '@angular/router';
import { PasswordInputComponent } from '../../../shared/widgets/inputs/password-input/password-input.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    HeaderComponent,
    BackButtonDirective,
    SvgIconComponent,
    GeneralInputComponent,
    GeneralInputDirective,
    GeneralButtonDirective,
    PasswordInputComponent,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css',
})
export class ResetPasswordComponent {
  authService = inject(AuthService);
  store = inject(Store<AppState>);
  router = inject(Router);
  @Input() token = '';

  authForm = new FormGroup(
    {
      password: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      token: new FormControl(null, {
        validators: [Validators.required],
      }),
    },
    {
      validators: [customPasswordValidator],
      updateOn: 'change',
    }
  );

  mutation = injectMutation((client) => ({
    mutationFn: (data: any) => this.authService.resetPassword(data),
    onSuccess: (data: any) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Password successfully reset',
        })
      );
      this.authForm.reset();
      this.router.navigate(['/'], {
        queryParams: {
          a: 'login',
        },
      });
    },
    onError: (err) => {
      console.log('err :>> ', err);
      const error = (err as any)?.error?.message;
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: error || 'Unable to reset password. Try again',
        })
      );
    },
  }));

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.token) {
      this.authForm.patchValue({
        token: this.token,
      });
    }
  }

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.mutation.mutate({
      password: this.authForm.value.password,
      confirmPassword: this.authForm.value.confirmPassword,
      token: this.authForm.value.token,
    });
  }
}
