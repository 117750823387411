import { createReducer, on } from '@ngrx/store';
import { Action } from '../../models';
import * as billActions from '../actions/bill.actions';
import { immerOn } from 'ngrx-immer/store';
import { ObjectLiteral } from '../../models/ObjectLiteral';

export type LookupProps = {
  id: number;
  displayField: string;
};

export interface BillState {
  //   createBillData: ObjectLiteral | null;
  selectedBill: ObjectLiteral | null;
  billInformation: ObjectLiteral | null;
  revenueBillToEdit: ObjectLiteral | null;
  expertSystemData: ObjectLiteral | null;
  formData: ObjectLiteral | null;
  attributeOptions: ObjectLiteral | null;
}

export const initialLookupState: BillState = {
  //   createBillData: null,
  selectedBill: null,
  billInformation: null,
  revenueBillToEdit: null,
  expertSystemData: null,
  formData: null,
  attributeOptions: null,
};

const reducer = createReducer(
  initialLookupState,
  //   on(billActions.createBillAction, (state, options) => ({
  //     ...state,
  //     createBillData: options.data,
  //   })),
  on(billActions.saveSelectedBillAction, (state, options) => {
    return {
      ...state,
      selectedBill: options.data,
    };
  }),
  on(billActions.saveBillInfoAction, (state, options) => {
    return {
      ...state,
      billInformation: options.data,
    };
  }),
  on(billActions.revenueBillToEditAction, (state, options) => {
    return {
      ...state,
      revenueBillToEdit: options.data,
    };
  }),
  on(billActions.saveExpertSystemDataAction, (state, options) => {
    return {
      ...state,
      expertSystemData: options.data,
    };
  }),
  on(billActions.saveFormDataAction, (state, options) => {
    return {
      ...state,
      formData: options.data,
    };
  }),
  on(billActions.saveAttributeOptionsAction, (state, options) => ({
    ...state,
    attributeOptions: { ...state.attributeOptions, ...(options.data || {}) },
  })),
  on(billActions.resetBillStore, (state, action) => ({
    ...initialLookupState,
  }))
);

export function billReducer(state: BillState, action: Action) {
  return reducer(state, action);
}
