import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  resetQueryAction,
  setQueryAction,
  setStateAction,
} from '../store/actions/admin-settlement-report.actions';

@Injectable()
export class AdminSettlementReportEffects {
  constructor(private actions$: Actions) {}

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => setStateAction({ ...payload }))
      // switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) =>
        setStateAction({
          rhId: '',
          status: '',
          minDate: '',
          maxDate: '',
        })
      )
      // switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );
}
