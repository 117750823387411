<div *ngIf="loader.isLoading$ | async" [ngClass]="{ relative: true }">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    [showSpinner]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
  >
</div>
