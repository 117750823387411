import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { changePasswordAction } from '../store/actions/auth.actions';
import { catchError, finalize, map, of, switchMap } from 'rxjs';
import { errorToastAction } from '../store/actions/toast.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store
  ) {}

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePasswordAction),
      switchMap(
        ({ password, confirmPassword, oldPassword, onSuccess, setLoading }) => {
          if (setLoading) setLoading(true);

          return this.authService
            .changePassword({
              password,
              confirmPassword,
              oldPassword,
            })
            .pipe(
              map((response) => {
                if (onSuccess) onSuccess();
                return response;
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while changing password',
                  })
                )
              ),
              finalize(() => {
                if (setLoading) setLoading(false);
              })
            );
        }
      )
    )
  );
}
