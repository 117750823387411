<app-protected-layout>
  <div class="pl-10 text-start">
    <p
      class="text-[#2e2409] text-sm bg-[#FFF9EB] w-full max-w-[769px] py-2 px-3 flex items-start justify-start gap-2 font-normal mt-[51px] mb-[35px]"
    >
      <svg-icon
        src="assets/svgs/exclamation_circled.svg"
        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      ></svg-icon>
      <span
        >Any member of the public can create an account, kindly note you will be
        a director, trustee or a proprietor/partner during the registration
        process. Please provide the necessary details.</span
      >
    </p>
  </div>
  <div [ngClass]="{ 'flex flex-1': true }">
    <div class="flex flex-col pl-10 stepper h-[447px] flex-1 max-w-[500px]">
      @for (step of steps; track $index) {
      <div
        [ngClass]="{
          'flex gap-[19px] pr-[72px]': true,
          'flex-1': $index !== steps.length - 1
        }"
      >
        <div class="flex flex-col items-center py-1">
          <span
            [ngClass]="{
              'stepper-span flex rounded-full w-11 h-11 min-w-[44px] min-h-[44px] border border-[#e1e1e1] justify-center items-center [&_svg_path]:fill-black flex-col': true,
              'bg-tw-primary [&_svg_path]:fill-white border-tw-primary':
                currentStep() >= step.index,
              'mb-1': $index !== steps.length - 1
            }"
          >
            <svg-icon
              [src]="
                currentStep() > step.index
                  ? 'assets/svgs/check.svg'
                  : step.index === 1
                  ? 'assets/svgs/user.svg'
                  : step.index === 2
                  ? 'assets/svgs/lock.svg'
                  : 'assets/svgs/mail-open.svg'
              "
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            ></svg-icon>
          </span>
          <span
            *ngIf="$index !== steps.length - 1"
            [ngClass]="{ '!bg-tw-primary': currentStep() > step.index }"
          ></span>
        </div>
        <div class="flex gap-2">
          <p class="whitespace-nowrap text-[#5D5E5E]">Step {{ step.index }}</p>
          <div class="flex flex-col gap-[10px]">
            <h3 class="text-[#000] text-lg font-semibold">
              {{ step.data.heading }}
            </h3>
            <p class="text-[#5D5E5E] text-sm max-w-[324px]">
              {{ step.data.text }}
            </p>
          </div>
        </div>
      </div>
      }
    </div>
    <div [ngClass]="{ 'p-8 flex-1 border-l-[1.2px] border-[#E1E1E1]': true }">
      <h4 [ngClass]="{ 'text-[#292929] font-bold text-xl pb-8': true }">
        Upload Document
      </h4>
      <form
        [formGroup]="authForm"
        (ngSubmit)="onSubmit()"
        class="flex flex-col flex-1 w-full px-4 max-w-[614px]"
      >
        <div
          class="text-[#5D5E5E] flex gap-4 flex-col relative mb-4"
          *ngIf="!queryResponse.isPending(); else skeleton"
        >
          @for (documentType of queryResponse.data(); track documentType.id) {
          <div [ngClass]="{ 'flex gap-[10px]': true }">
            <div
              [ngClass]="{
                'flex-1 rounded border border-[#E1E1E1] text-[#5D5E5E] text-sm p-3 font-medium shrink-0': true
              }"
            >
              {{ documentType.displayField }}
            </div>
            <div
              [ngClass]="{
                'flex-1 border border-[#E1E1E1] text-[#5D5E5E] rounded flex min-w-0 px-4 gap-2': true
              }"
            >
              <label
                [for]="documentType.id"
                [ngClass]="{
                  'py-3 cursor-pointer flex items-center justify-center flex-1 min-w-0': true
                }"
              >
                <input
                  type="file"
                  name=""
                  [id]="documentType.id"
                  [ngClass]="{ hidden: true }"
                  (input)="changeFileHandler($event, documentType.id)"
                  accept="image/png, image/jpeg, image/jpg"
                  (click)="clickHandler($event)"
                />
                <div
                  [ngClass]="{
                    'flex flex-col justify-center items-center flex-1 min-w-0 truncate': true
                  }"
                >
                  <div
                    *ngIf="selectDocumentWithFiles()[documentType.id]"
                    [ngClass]="{
                      'flex items-center gap-2 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden truncate min-w-0': true
                    }"
                  >
                    <span [ngClass]="{ ' min-w-0': true }">
                      <svg-icon
                        src="assets/svgs/file_icon.svg"
                        [svgStyle]="{ 'width.px': 17, 'height.px': 20 }"
                      ></svg-icon>
                    </span>
                    <p
                      [ngClass]="{
                        'flex-1 text-xs font-medium text-[#5D5E5E] min-w-0 whitespace-nowrap text-ellipsis overflow-hidden truncate': true
                      }"
                    >
                      {{ selectDocumentWithFiles()[documentType.id]?.docName }}
                    </p>
                  </div>
                  <p
                    [ngClass]="{ 'text-sm text-[#333]': true }"
                    *ngIf="!selectDocumentWithFiles()[documentType.id]"
                  >
                    Upload image or
                    <span
                      [ngClass]="{
                        'text-tw-primary font-bold text-xs underline': true
                      }"
                      >Browse</span
                    >
                  </p>
                </div>
              </label>
              <div
                [ngClass]="{
                  'items-center gap-2 hidden': true,
                  '!flex': selectDocumentWithFiles()[documentType.id]
                }"
              >
                <span [ngClass]="{ 'text-[10px] leading-[14px]': true }">{{
                  selectDocumentWithFiles()[documentType.id]?.size
                }}</span>
                <button type="button" (click)="deleteHandler(documentType.id)">
                  <svg-icon
                    src="assets/svgs/delete_icon.svg"
                    [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                  ></svg-icon>
                </button>
              </div>
            </div>
          </div>

          }
        </div>
        <!-- <div [ngClass]="{'pt-4': true}">
                    <p [ngClass]="{'pb-2': true}">Upload NIN Slip</p>
                    <label for="agent_doc_upload"
                        [ngClass]="{'border border-dashed rounded-lg py-[60px] flex justify-center items-center cursor-pointer': true}">
                        <input type="file" name="" id="agent_doc_upload" [ngClass]="{'hidden': true}"
                            (input)="changeFileHandler($event,'')" accept="image/png, image/jpeg, image/jpg"
                            (click)="clickHandler($event)">
                        <div [ngClass]="{'flex flex-col justify-center items-center': true}">
                            <svg-icon src="assets/svgs/document-upload.svg"
                                [svgStyle]="{ 'width.px':17,'height.px':17 }"></svg-icon>
                            <p [ngClass]="{'text-sm text-[#333]': true}">Upload image or <span
                                    [ngClass]="{'text-tw-primary font-bold text-xs underline': true}">Browse</span></p>
                            <p [ngClass]="{'pt-2 text-[#5D5E5E]': true}">Supports .png .jpeg formats</p>
                        </div>
                    </label>
                    <div class="selected-docs" *ngIf="selectedDocs.length">
                        <h5 [ngClass]="{'font-semibold text-base pt-3': true}">Selected documents</h5>
                        @for (item of selectedDocs; track item.docId) {
                        <div [ngClass]="{'text-sm text-tw-primary font-semibold italic underline': true}">
                            {{item.docName}}</div>
                        }
                    </div>
                    <div [ngClass]="{'pt-8': true}">
                        <h4 [ngClass]="{'text-[#292929] text-base font-semibold pb-4': true}">Supported Documents</h4>
                        <ul [ngClass]="{'list-disc pl-5 pb-8': true}">
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Image of Signature (JPEG/PNG format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Passport Photograph (JPEG/PNG format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Evidence of Identification (PDF format)
                            </li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Qualifying Certificate (PDF format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">NYSC Discharge Certificate/Exemption
                                Certificate (PDF format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Current Practicing Fee
                                Receipt (PDF format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Other Documents (PDF format)</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Subscription fee receipt (PDF format) for
                                firm</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Certificate of
                                registration (PDF format) for firm</li>
                            <li [ngClass]="{'text-sm text-[#5D5E5E]': true}">Qualifying certificate (PDF) for firm- The
                                user uploads
                                all necessary documents and
                                clicks</li>
                        </ul>
                        <button appGeneralButton>Next</button>
                    </div>
                </div> -->
      </form>
    </div>
  </div>
</app-protected-layout>

<ng-template #skeleton>
  @for (item of [1,2,3]; track $index) {

  <div [ngClass]="{ 'flex gap-4': true }">
    <div [ngClass]="{ 'flex-1': true }">
      <ngx-skeleton-loader
        count="3"
        animation="progress"
        [theme]="{
          'height.px': 40,
          'background-color': '#E1E1E1',
          border: '1px solid white',
          'border-radius': '5px'
        }"
      />
    </div>
    <div [ngClass]="{ 'flex-1': true }">
      <ngx-skeleton-loader
        count="3"
        animation="pulse"
        [theme]="{
          'height.px': 40,
          'background-color': '#E1E1E1',
          border: '1px solid white',
          'border-radius': '5px'
        }"
      />
    </div>
  </div>

  }
</ng-template>
