import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilKeyChanged,
  finalize,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserService } from '../services/user.service';
import {
  deleteUsersAction,
  resetListQueryAction,
  setDocAction,
  setListAction,
  setListQueryAction,
  setSearchAction,
  setStateAction,
} from '../store/actions/user.actions';
import { getUserStore } from '../store/selectors/user.selectors';
import { errorToastAction } from '../store/actions/toast.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store
  ) {}

  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListAction),
      withLatestFrom(this.store.pipe(select(getUserStore))),
      switchMap(([payload, userStore]) => {
        // set listLoading to true
        this.store.dispatch(setStateAction({ listLoading: true }));

        return this.userService
          .getAllUsers({
            pageNumber: userStore.page,
            pageSize: userStore.size,
            search: userStore.search,
            sortField: userStore.sortField,
            sortOrder: userStore.sortOrder,
          })
          .pipe(
            map((response) =>
              setStateAction({
                total: response.totalRecords,
                list: response.data,
              })
            ),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while fetching users data',
                })
              )
            ),
            finalize(() =>
              this.store.dispatch(
                setStateAction({
                  listLoading: false,
                })
              )
            )
          );
      })
    )
  );

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListQueryAction),
      map((payload) => this.store.dispatch(setStateAction({ ...payload }))),
      map(() => setListAction(null))
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetListQueryAction),
      map((payload) =>
        this.store.dispatch(
          setStateAction({
            page: 1,
            sortOrder: '',
            sortField: '',
            search: '',
          })
        )
      ),
      map(() => setListAction(null))
    )
  );

  setSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({ search: payload.keyword, page: 1 })
        );
        return payload;
      }),
      debounceTime(300),
      distinctUntilKeyChanged('keyword'),
      map(() => {
        return setListAction(null);
      })
    )
  );

  loadDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDocAction),
      switchMap((action) => {
        // set docLoading to true
        this.store.dispatch(setStateAction({ docLoading: true, doc: null }));

        return this.userService.getOneUserById({ id: action.id }).pipe(
          map((response) =>
            setStateAction({
              doc: { ...response.userRegistration, ...response.user },
            })
          ),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching user data',
              })
            )
          ),
          finalize(() =>
            this.store.dispatch(setStateAction({ docLoading: false }))
          )
        );
      })
    )
  );

  deleteUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUsersAction),
      switchMap(({ userIds, setLoading, onSuccess }) => {
        if (setLoading) setLoading(true);

        return this.userService
          .deleteUsers({
            userIds,
          })
          .pipe(
            map((response) => {
              if (onSuccess) onSuccess();
              return response;
            }),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while deleting users',
                })
              )
            ),
            finalize(() => {
              if (setLoading) setLoading(false);
            })
          );
      })
    )
  );
}
