import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ViewEncapsulation,
} from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ThemePalette } from '@angular/material/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule, NgxSpinnerModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.css',
  //   encapsulation: ViewEncapsulation.ShadowDom,
  //   schemas: [CUSTOM_ELEMENTS_SCHEMA],
  host: {
    class: 'relative',
  },
})
export class SpinnerComponent {
  constructor(public loader: LoaderService) {}
}
